import type {AxiosError, AxiosResponse} from 'axios';
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/vue-query';
import {cloudClient} from '@console/api/cloud';
import {deslash} from '@console/helpers/router';
import type {VueMutationObserverOptions} from '@tanstack/vue-query/build/lib/useMutation';
import router from '@console/router';
import normalizeError from '@console/helpers/normalizeError';
import type {MaybeRef, Ref} from 'vue';
import {computed, reactive, ref, unref} from 'vue';
import type {PaginationMeta} from '@/console';
import qs from 'qs';
import {useRoute} from 'vue-router';

type QueryKeyT = (string | object | undefined)[];

export const urlToQueryKey = (
  url: string | Ref<string>,
  params?: object
): QueryKeyT => {
  const segments = deslash(unref(url)).split('/');
  const contextId = JSON.parse(
    localStorage.getItem('contextElementId') as string
  );

  const queryKey = [contextId, ...segments];

  if (params) {
    queryKey.push(params);
  }

  return queryKey;
};

export const handleApiError = async (error: any) => {
  const {status} = normalizeError(error);

  if (status === 404) {
    return router.push({name: 'NotFound'});
  }
};

// https://www.smashingmagazine.com/2022/01/building-real-app-react-query/
export const useGet = <T>(
  url: string | null,
  params?: MaybeRef<object>,
  config?: UseQueryOptions<T, Error, T, QueryKeyT>
) => {
  return useQuery<T, Error, T, QueryKeyT>(
    urlToQueryKey(url!, params),
    () =>
      cloudClient
        .get<T>(url!, {params: unref(params)})
        .then((response: AxiosResponse<T>) => response.data),
    {
      enabled: !!url,
      keepPreviousData: true,
      onError: handleApiError,
      retry: false,
      ...config,
    }
  );
};

export const usePaginatedQuery = <T, P = object>(
  url: string,
  initialParams: MaybeRef<P> | object = {},
  config: UseQueryOptions<T, Error, T, QueryKeyT> = {}
) => {
  const route = useRoute();
  const meta = ref<PaginationMeta>({
    from: 1,
    to: 1,
    total: 1,
    per_page: 10,
    current_page: 1,
    last_page: 1,
  });

  const params = reactive({
    ...unref(initialParams),
    page: 1,
    ...route.query,
  });

  function goToPage(page: number) {
    if (page > meta.value.last_page) {
      params.page = meta.value.last_page;
    }

    if (page < 1) {
      params.page = 1;
    }

    params.page = page;
    history.pushState({...params}, '', `${route.path}?${qs.stringify(params)}`);
  }

  function nextPage() {
    goToPage(params.page + 1);
  }

  function previousPage() {
    goToPage(params.page - 1);
  }

  const showPagination = computed(() => meta.value.last_page !== 1);

  async function fetcher(params: {page?: number}) {
    const response = await cloudClient.get(url!, {params});
    const responseData = response.data;

    meta.value = responseData.meta;
    return responseData;
  }

  const response = useQuery({
    queryKey: urlToQueryKey(url!, params),
    queryFn: () => fetcher(params),
    keepPreviousData: true,
    enabled: Boolean(url),
    ...config,
  });

  return {
    ...response,
    meta,
    params,
    previousPage,
    nextPage,
    showPagination,
    goToPage,
  };
};

const useGenericMutation = <T, S>(
  func: (data: T | S) => Promise<AxiosResponse<S>>,
  url: string,
  params?: object,
  updater?: ((oldData: T, newData: S) => T) | undefined,
  onMutate?: (data: T | S) => Promise<unknown> | void,
  options?: VueMutationObserverOptions<AxiosResponse, AxiosError, T | S>
) => {
  const queryClient = useQueryClient();
  const queryKey = urlToQueryKey(url!, params);

  return useMutation<AxiosResponse, AxiosError, T | S>({
    mutationFn: func,

    onMutate: async (data?) => {
      await queryClient.cancelQueries(queryKey);

      const previousData = queryClient.getQueryData(queryKey);

      queryClient.setQueryData<T>(queryKey, (oldData) => {
        return updater ? updater(oldData!, data as S) : (data as T);
      });

      if (onMutate) {
        await onMutate(data);
      }

      return previousData;
    },
    onError: async (err, _, context) => {
      queryClient.setQueryData(queryKey, context);
      await handleApiError(err);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data.data);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
    ...(options || {}),
  });
};

export const usePost = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T,
  onMutate?: (data: T | S) => Promise<unknown> | void,
  options?: VueMutationObserverOptions<AxiosResponse, AxiosError, T | S>
) => {
  return useGenericMutation<T, S>(
    (data?) => cloudClient.post(url, data),
    url,
    params,
    updater,
    onMutate,
    options
  );
};

export const useDelete = <T>(
  url: string,
  params?: object,
  updater?: (oldData: T, id: string | number) => T,
  onMutate?: () => Promise<unknown> | void,
  options?: VueMutationObserverOptions<
    AxiosResponse,
    AxiosError,
    T | string | number
  >
) => {
  const queryClient = useQueryClient();

  return useGenericMutation<T, string | number>(
    (id) => cloudClient.delete(`${url}/${id}`),
    url,
    params,
    updater,
    onMutate,
    {
      onSettled: async () => {
        await queryClient.invalidateQueries();
      },
      ...options,
    }
  );
};

export const usePut = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T,
  onMutate?: (data: T | S) => Promise<unknown> | void,
  options?: VueMutationObserverOptions<AxiosResponse, AxiosError, T | S>
) => {
  return useGenericMutation<T, S>(
    (data) => cloudClient.put(url, data),
    url,
    params,
    updater,
    onMutate,
    options
  );
};
